<template>
  <div class="box-tools" v-if="isReady">
    <span
      class="btn btn-box-tool btn-img btn-undo"
      :class="{ disabled: !canUndo }"
      :disabled="!canUndo"
      :title="$t('undo_action')"
      @click.stop.prevent="onClick('undo')"
    />
    <span
      class="btn btn-box-tool btn-img btn-redo"
      :class="{ disabled: !canRedo }"
      :disabled="!canRedo"
      :title="$t('redo_action')"
      @click.stop.prevent="onClick('redo')"
    />
    <span
      class="btn btn-box-tool fa"
      style="font-size: 1.1em"
      :class="isPanelLocked ? 'fa-lock is-active' : 'fa-unlock'"
      :title="`${this.$t(
        `synoptic.${this.isPanelLocked ? 'unlock' : 'lock'}`
      )} ${this.$t('controls').toLowerCase()}`"
      @click.stop.prevent="onClick('toggleLockControls')"
    />
    <span
      class="btn btn-box-tool fa fa-hand-pointer-o"
      style="padding-top: 4px"
      :class="areControlsSelectable ? 'is-active' : ''"
      :title="
        $t(
          `synoptic.${
            areControlsSelectable ? 'disable_select_mode' : 'enable_select_mode'
          }`
        )
      "
      @click.stop.prevent="onClick('toggleSelectToChange')"
    />
    <span
      class="btn btn-box-tool fa fa-file-o"
      style="padding-top: 4px"
      :title="$t('new_panel')"
      @click.stop.prevent="onClick('clearControlList')"
    />
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "SynopticEditorToolbar",
  data() {
    return {
      canUndo: false,
      canRedo: false,
      panelName: ""
    }
  },
  computed: {
    panel() {
      return this.$store.getters["synoptic/panel"]
    },
    panelControls() {
      return this?.panel?.options?.controls
    },
    isPanelLocked() {
      return this?.panel?.locked;
    },
    areControlsSelectable() {
      return this.$store.getters["synoptic/selectToChange"];
    },
    isReady() {
      return this.panel && this.panel.name == this.panelName;
    }
  },
  watch: {
    panelControls: {
      handler(n, o) {
        if (!this.isReady) return;
        if (this.skip) {
          this.skip = false;
          return;
        }
        else if (n && o) this._save(o);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    save(value) {
      let o = this.undoList.length && JSON.stringify(this.undoList[this.undoList.length - 1]) || "";
      let n = JSON.stringify(value);
      if (o != n) {
        this.undoList.push(JSON.parse(n));
        this.validate();
      }
    },
    validate() {
      this.canUndo = this.undoList.length ? true : false;
      this.canRedo = this.redoList.length ? true : false;
    },
    undo() {
      if (!this.canUndo) return;
      if (this.undoList.length) {
        this.redoList.push(this.undoList.pop());
        this.$store.dispatch("synoptic/setControls", this.undoList.length ? this.undoList[this.undoList.length - 1] : this.original);
        this.skip = true;
      }
      this.validate();
    },
    redo() {
      if (!this.canRedo) return;
      if (this.redoList.length) {
        this.$store.dispatch("synoptic/setControls", this.redoList.length ? this.redoList[this.redoList.length - 1] : this.original);
        this.undoList.push(this.redoList.pop());
        this.skip = true;
      }
      this.validate();
    },
    onClick(action) {
      switch (action) {
        case "undo":
          this.$root.$emit('undo');
          break;
        case "redo":
          this.$root.$emit('redo');
          break;
        case "toggleLockControls":
          this.$store.dispatch("synoptic/toggleLockControls");
          break;
        case "toggleSelectToChange":
          this.$store.dispatch("synoptic/toggleSelectToChange");
          break;
        case "clearControlList":
          let wrapper = document.createElement("div");
          wrapper.innerHTML = this.$t(
            "this_will_reset_all_of_your_unpushed_changes"
          );
          this.$swal({
            title: this.$t("are_you_sure"),
            content: wrapper,
            icon: "warning",
            buttons: [this.$t("cancel"), this.$t("confirm")]
          }).then((ok) => {
            if (!ok) return;
            this.undoList = [];
            this.redoList = [];
            this.validate();
            this.skip = true;
            this.$store.dispatch("synoptic/create", "SynopticPanel");
          });
          break;
      }
    }
  },
  beforeCreate() {
    // non reactive properties
    this.original = [];
    this.undoList = [];
    this.redoList = [];
    this.skip = false;
    this._save = debounce((payload) => {
      this.save(payload);
    }, 500);
  },
  created() {
    this.$nextTick(() => {
      let tm = setInterval(() => {
        if (this.panel) {
          clearInterval(tm); tm = null;
          this.panelName = this.panel.name;
          this.original = JSON.parse(JSON.stringify(this.panelControls));
          this.$root.$on("undo", this.undo);
          this.$root.$on("redo", this.redo);
        }
      }, 100, this);
    });
  },
  beforeDestroy() {
    this.$root.$off("undo", this.undo);
    this.$root.$off("redo", this.redo);
  }
};
</script>

<style lang="scss" scoped>
.box-tools .btn.btn-box-tool.btn-img {
  width: 20px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 50%;
}

.btn-img.disabled {
  opacity: 0.5;
}

.btn-undo,
.btn-undo:active {
  background-image: url(/static/common/images/undo.png);
}

.btn-redo,
.btn-redo:active {
  background-image: url(/static/common/images/redo.png);
}

// .box-tools > span.btn {
//   color: #333;
// }

.btn-box-tool {
  padding: 2px 5px;
  width: 24px;
  height: 24px;
  margin-inline: 1px;
  color: #333;
}

.box-tools .btn-box-tool {
  &:not(.is-active) {
    &:hover {
      background-color: #e6e6e6;
    }

    &:active {
      background-color: #d4d4d4;
    }
  }

  &.is-active {
    background-color: #3c8dbc;

    &:hover {
      background-color: #367fa9;
    }

    &:active {
      background-color: #204d74;
    }

    i {
      color: white;
    }
  }
}

.fa-redo {
  transform: rotateY(180deg);
}
</style>